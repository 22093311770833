@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: Manrope-Regular;
  src: url("../public/fonts/Manrope-Regular.ttf") format("truetype") ;
}
@font-face {
  font-family: Manrope-Light;
  src: url("../public/fonts/Manrope-Light.ttf") format("truetype") ;
}
@font-face {
  font-family: Manrope-ExtraLight;
  src: url("../public/fonts/Manrope-ExtraLight.ttf") format("truetype") ;
}
@font-face {
  font-family: Manrope-Medium;
  src: url("../public/fonts/Manrope-Medium.ttf") format("truetype") ;
}
@font-face {
  font-family: Manrope-SemiBold;
  src: url("../public/fonts/Manrope-SemiBold.ttf") format("truetype") ;
}
@font-face {
  font-family: Manrope-Bold;
  src: url("../public/fonts/Manrope-Bold.ttf") format("truetype") ;
}
@font-face {
  font-family: Manrope-ExtraBold;
  src: url("../public/fonts/Manrope-ExtraBold.ttf") format("truetype") ;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, Manrope-Regular, 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button,input,select {
  outline : none !important;
}
.active-nav span svg {
  fill : #386CB9 !important;
}
.nav-non span svg {
  fill : #898E92 !important;
}
.slider-im{
  direction: ltr !important;
}
*:focus {
  outline: none !important;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}


.rdp {
  --rdp-cell-size: 80px;
  --rdp-accent-color: #386CB9 !important;
  --rdp-background-color: #e7edff;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}
.rdp-cell{
  padding : 3px !important;
}


.gradient-bg {
  background-image: linear-gradient(to right, #00000070, #06125770) !important;
}

.swiper-button-prev {
  background-image: url('../public/icons/arrow-prev.svg') !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  top: 75% !important;
  width:3em !important;
  height:3em !important;
}
.swiper-button-next {
  background-image: url('../public/icons/arrow-next.svg') !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  top: 75% !important;
  width:3em !important;
  height:3em !important;

}
.swiper-button-next::after,.swiper-button-prev::after {
  display: none !important;
}
.swiper-pagination-horizontal{
  bottom : 0 !important;
}



@media (max-width: 767.98px){
  .swiper-button-prev,.swiper-button-next {
    display : none !important;
  }
}

.icon-nav{
  box-shadow : 0px -10px 20px #00000008 !important;
}



@layer components {
  .active-svg span span svg{
    @apply fill-blue-1 !important;
  }

  table{
    @apply rounded-xl !important
  }
}




